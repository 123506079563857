<template>
  <div id="base-plan" class="mb-10">
    <div>
      <b-card no-body>
        <b-card-text>
          <div class="row">
            <div class="col-md-12" style="background: white">
              <h5 class="mt-4">Finished goods report</h5>
              <hr />
              <div class="row mt-4">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">Reported Date</label>
                    <date-picker
                      :date-format="'YYYY-MM-DD'"
                      :shortcuts="dateoptions"
                      valueType="format"
                      v-model="reported_date"
                      :class="[
                        errors['reported_date'] ? 'border border-danger' : '',
                      ]"
                    ></date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12" style="background: white; font-size: 10px">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th colspan="4">
                      Product Details(as per factory standard)
                    </th>
                    <th colspan="11" style="text-align: center">Observation</th>
                  </tr>
                  <tr>
                    <th colspan="4" style="text-align: center">Parameters</th>
                    <th>Moisture Content</th>
                    <th>Total (Ash Dry Basis)</th>
                    <th>AIA (Dry Basis)</th>
                    <th>Acidity of extracted fat (DB)</th>
                    <th>Color (Top)</th>
                    <th>Bottom</th>
                    <th>Texture (Bite)</th>
                    <th>Packing of Biscuits (Sealing)</th>
                    <th>No. of Biscuits / Pkt</th>
                    <th>Biscuit Cell</th>
                    <th>Cream weight</th>
                  </tr>
                  <tr>
                    <th colspan="4" style="text-align: center">
                      Specifications
                    </th>
                    <th>3.5% (Max)</th>
                    <th>3.0% (Max)</th>
                    <th>0.1% (Max)</th>
                    <th>0.2% (Max)</th>
                    <th>Golden</th>
                    <th>Golden brown</th>
                    <th>Crunchy</th>
                    <th>Air tight</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>

                  <tr>
                    <th style="width: 5%">Variety:</th>

                    <th>Batch No.</th>
                    <th>Mfd</th>
                    <th>Average Gross Weight / Pkt. (g)</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item, index) in details"
                    :key="index"
                    :class="[item.error == 1 ? 'row_red' : '']"
                  >
                    <td class="ino" style="width: 8%">
                      <select
                        class="form-control"
                        :class="[
                          errors['biscuit_name'] ? 'border border-danger' : '',
                        ]"
                        id="exampleFormControlSelect1"
                        v-model="item.biscuit_name"
                      >
                        <option value="">Select Variety</option>

                        <option
                          v-for="(bis, index) in biscuits"
                          :key="index"
                          :value="bis.name"
                        >
                          {{ bis.name }}
                        </option>
                      </select>
                    </td>
                    <td class="inno">
                      <input
                        type="number"
                        :id="'detail_batch_no_' + index"
                        v-model="item.batch_no"
                        class="form-control"
                      />
                    </td>
                    <td class="inno">
                      <input
                        type="month"
                        :id="'detail_manufactured_' + index"
                        v-model="item.manufactured"
                        class="form-control"
                      />
                    </td>
                    <td class="inno">
                      <input
                        type="text"
                        :id="'detail_average_gross_weight_' + index"
                        v-model="item.average_gross_weight"
                        class="form-control"
                      />
                    </td>
                    <td class="inno">
                      <input
                        type="text"
                        :id="'detail_moisture_content_' + index"
                        v-model="item.moisture_content"
                        class="form-control"
                      />
                    </td>
                    <td class="inno">
                      <input
                        type="text"
                        :id="'detail_total_dry_basis_' + index"
                        v-model="item.total_dry_basis"
                        class="form-control"
                      />
                    </td>
                    <td class="inno">
                      <input
                        type="text"
                        :id="'detail_aia_dry_basis_' + index"
                        v-model="item.aia_dry_basis"
                        class="form-control"
                      />
                    </td>
                    <td class="inno">
                      <input
                        type="text"
                        :id="'detail_acidity_extracted_fat_' + index"
                        v-model="item.acidity_extracted_fat"
                        class="form-control"
                      />
                    </td>

                    <td class="inno">
                      <input
                        type="text"
                        :id="'detail_top_color_' + index"
                        v-model="item.top_color"
                        class="form-control"
                      />
                    </td>
                    <td class="inno">
                      <input
                        type="text"
                        :id="'detail_bottom_color_' + index"
                        v-model="item.bottom_color"
                        class="form-control"
                      />
                    </td>
                    <td class="inno">
                      <input
                        type="text"
                        :id="'detail_texture_' + index"
                        v-model="item.texture"
                        class="form-control"
                      />
                    </td>
                    <td class="inno">
                      <input
                        type="text"
                        :id="'detail_packing_of_biscuit_' + index"
                        v-model="item.packing_of_biscuit"
                        class="form-control"
                      />
                    </td>
                    <td class="inno">
                      <input
                        type="text"
                        :id="'detail_no_of_biscuits_per_packt_' + index"
                        v-model="item.no_of_biscuits_per_packt"
                        class="form-control"
                      />
                    </td>
                    <td class="inno">
                      <input
                        type="text"
                        :id="'detail_biscuit_cell_' + index"
                        v-model="item.biscuit_cell"
                        class="form-control"
                      />
                    </td>
                    <td class="inno">
                      <input
                        type="text"
                        :id="'detail_cream_weight_' + index"
                        v-model="item.cream_weight"
                        class="form-control"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="btn btn-smplan-fixed">
            <button
              ref="kt_submit_base_plan"
              class="btn btn-warning mt-2 mb-2 mr-5"
              @click="goback()"
            >
              Go back
            </button>
            <button
              ref="kt_submit_base_plan"
              vi
              class="btn btn-success mt-2 mb-2 mr-3"
              @click="update()"
            >
              Update
            </button>
          </div>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
// import Swal from "sweetalert2/dist/sweetalert2.js";

import {
  FETCH_FINISHED_GOODS_DETAIL_LISTS,
  UPDATE_FINISHED_GOODS_REPORT,
  FETCH_BISCUITS_LISTS,
} from "@/core/services/store/actions.type";
// import moment from "moment";

import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
export default {
  computed: {
    ...mapGetters(["currentUser"]),
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      isEdit: false,
      dateoptions: [{ text: "Today", onClick: () => new Date() }],

      details: [],

      temp_start_time: "",
      temp_end_time: "",
      old_temp_start_time: 0,
      results: [],
      errors: {},
      biscuits: [],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard",
        },
        {
          id: 2,
          title: "Update Finished Goods Analysis Report",
          route: "",
        },
      ],
      reported_date: "",
      add_status: 1,
      error_status: 1,
    };
  },
  created() {
    var id = this.$route.params.id;
    this.$store.dispatch(FETCH_BISCUITS_LISTS).then((data) => {
      this.biscuits = data;
    });
    this.$store.dispatch(FETCH_FINISHED_GOODS_DETAIL_LISTS, id).then((data) => {
      this.details = [];
      this.reported_date = data.reported_date;
      this.doc_id = data.doc_id;
      this.doc_no = data.doc_no;
      this.doc_prefix = data.doc_prefix;
      data.data.map((item) => {
        this.details.push({
          id: item.id,
          biscuit_name: item.biscuit_name,
          batch_no: item.batch_no,
          manufactured: item.manufactured,
          average_gross_weight: item.average_gross_weight,
          moisture_content: item.moisture_content,
          total_dry_basis: item.total_dry_basis,
          aia_dry_basis: item.aia_dry_basis,
          acidity_extracted_fat: item.acidity_extracted_fat,
          top_color: item.top_color,
          bottom_color: item.bottom_color,
          texture: item.texture,
          packing_of_biscuit: item.packing_of_biscuit,
          no_of_biscuits_per_packt: item.no_of_biscuits_per_packt,
          biscuit_cell: item.biscuit_cell,
          cream_weight: item.cream_weight,
        });
      });

      //   this.details.push(biscuits);
    });

    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  },
  methods: {
    planpush() {
      let plan = {
        biscuit_name: "",
        batch_no: "",
        manufactured: "",
        average_gross_weight: "",
        moisture_content: "",
        total_dry_basis: "",
        aia_dry_basis: "",
        acidity_extracted_fat: "",
        top_color: "",
        bottom_color: "",
        texture: "",
        packing_of_biscuit: "",
        no_of_biscuits_per_packt: "",
        biscuit_cell: "",
        cream_weight: "",
      };
      this.details.push(plan);
      this.add_status = 1;
    },
    reset() {
      this.plan = {
        biscuit_name: "",
        batch_no: "",
        manufactured: "",
        average_gross_weight: "",
        moisture_content: "",
        total_dry_basis: "",
        aia_dry_basis: "",
        acidity_extracted_fat: "",
        top_color: "",
        bottom_color: "",
        texture: "",
        packing_of_biscuit: "",
        no_of_biscuits_per_packt: "",
        biscuit_cell: "",
        cream_weight: "",
      };
    },
    update() {
      const det = this.details.filter((data) => {
        return (
          data.biscuit_name != "" &&
          (data.batch_no != "" ||
            data.manufactured != "" ||
            data.average_gross_weight != "" ||
            data.moisture_content != "" ||
            data.total_dry_basis != "" ||
            data.aia_dry_basis != "" ||
            data.acidity_extracted_fat != "" ||
            data.top_color != "" ||
            data.bottom_color != "" ||
            data.texture != "" ||
            data.packing_of_biscuit != "" ||
            data.no_of_biscuits_per_packt != "" ||
            data.biscuit_cell != "" ||
            data.cream_weight != "")
        );
      });
      let msg = "Please fill all the value";
      var err = 0;
      this.errors = {};
      if (this.reported_date == "") {
        this.errors["reported_date"] = "This field is required";
        err = 1;
      }

      if (err == 1) {
        this.$toastr.w(msg);
        return 0;
      }
      if (det.length < 1) {
        let msg = "Please add atleast one record";
        this.$toastr.w(msg);
        return 0;
      }
      var formData = {
        details: det,
        reported_date: this.reported_date,
      };

      const submitButton = this.$refs["kt_submit_base_plan"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(UPDATE_FINISHED_GOODS_REPORT, formData)
        .then((res) => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.s(res.msg);
          this.$router.push({
            name: "client.qualitycontrol.finishedgoods",
          });
        })
        .catch(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.e("Something went wrong !");
        });
    },
    removeplan(index) {
      // console.log(weekday, index);
      this.details.splice(index, 1);
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.mx-input-wrapper {
  border: 1px solid #e4e6ef;
  border-radius: 0.45rem;
}
.mx-datepicker .mx-input {
  height: 2.8rem;
}
.row_red {
  background-color: #f89494 !important;
}
.base-plan {
  .btn-plan-fixed {
    position: fixed;
    right: 3%;
    top: 89%;
    width: 75%;
    background-color: black;
  }
}
.btn-smplan-fixed {
  position: fixed;
  top: 93%;
  background-color: #ffff;
  width: calc(71vw - 0%);

  button {
    float: right;
  }
}
table {
  .baseplan-header {
    background: #b4cfe2;
  }
}
.form-control {
  font-size: 10px !important;
  padding: 0.25rem 0.25rem;
  height: calc(1.5em + 1.3rem);
}
.table thead th,
.table thead td {
  font-size: 12px !important;
}
.table td,
.table th {
  padding: 0.25rem;
}
</style>
<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
.icon-green {
  color: green;
}
.icon-red {
  color: red;
}
.mx-input {
  width: 140px;
}
</style>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.action {
  width: 20%;
}
.datetime {
  width: 10%;
}
.ino {
  width: 10%;
}
.form-inline-flex {
  display: inline-flex;
}
.label1 {
  margin-right: 2%;
  margin-top: 2%;
}
.schedule-input {
  width: 27%;
  margin-right: 2%;
}
.label2 {
  margin-top: 2%;
}
</style>
